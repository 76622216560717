import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "fileName", "submitButton", "bank", "invoiceType"]

  static values = {
    loggedIn: Boolean,
    banksData: Array
  }

  connect() {
    this.submitButtonTarget.disabled = true;
    this.banks = this.banksDataValue;
    this.updateInvoiceTypeOptions();
  }

  chooseFile() {
    this.inputTarget.click();
  }

  displayFileName() {
    const file = this.inputTarget.files[0];
    if (file) {
      this.fileNameTarget.textContent = `Arquivo selecionado: ${file.name}`;
      
      if(this.loggedInValue) {
        this.submitButtonTarget.disabled = false;
      }
    } else {
      this.fileNameTarget.textContent = "Nenhum arquivo selecionado";
      this.submitButtonTarget.disabled = true;
    }
  }

  updateInvoiceTypeOptions() {
    const bank = this.bankTarget.value;
    const invoiceTypeSelect = this.invoiceTypeTarget;

    invoiceTypeSelect.innerHTML = "";

    const bankData = this.banks.find(b => b.bank === bank);
    const options = bankData ? bankData.document_types : [];

    options.forEach(option => {
      const opt = document.createElement("option");
      opt.value = option.value;
      opt.textContent = option.text;
      invoiceTypeSelect.appendChild(opt);
    });
  }

  bankChanged() {
    this.updateInvoiceTypeOptions();
  }
}
