// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"
import { registerControllers } from 'stimulus-vite-helpers'

// Eager load all controllers defined in the import map under controllers/**/*_controller
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
